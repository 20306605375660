import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/Seo"
import { Link, graphql } from "gatsby";
import { OpeningTimes } from "../components/OpeningTimes";

export interface IAppProps
{
    data: any;
}

interface IBookingPageState
{
}

export const query = graphql`
query BookQuery {
    metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
        original{src}
        }
    }
}
`;

export default class BookingPage extends React.Component<IAppProps, IBookingPageState>
{
    private scriptRef: any;

    constructor(props: IAppProps)
    {
        super(props);

        this.state = {
        };

        this.scriptRef = React.createRef();
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="book">
                <SEO title="Book your table" image={this.props.data.metaImage.childImageSharp.original.src} />

                <section className="hero">
                    <div className="hero-content text-center">
                        <div className="content">
                            <h1>Book your table</h1>
                            <p className="lead">
                                Come and enjoy some delicious Chinese cuisine with us!
                            </p>

                            <div className="button-group" style={{ width: "300px", maxWidth: "100%", margin: "0 auto" }}>
                                <Link to="/menus" title="View our menus" className="btn btn-solid">
                                    View our menus
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="book-a-table">

                    <div className="map-container">
                        <h2 className="text-uppercase">Book a table</h2>

                        <OpeningTimes />

                        <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
                            Give us a call
                        </h5>
                        <p>
                            To book a table please give us a call on <a href="tel:01604602818">01604 602818</a>.
                        </p>
                        <p>
                            Alternatively you can book a table via text <a href="tel:07830744437">07830 744437</a>.
                        </p>

                        <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
                            Book online
                        </h5>
                        <p>
                            To book a table online, please use TableAgent below.
                        </p>
                        <iframe
                            src="https://tableagent.com/iframe/oriental-garden/v/medium/"
                            style={{ borderStyle: "none", border: "0px", minWidth: "350px", maxWidth: "350px", minHeight: "480px", height: "480px" }}
                            width="100%"
                            height="480px">
                        </iframe>

                        <div>
                            <address>
                                Oriental Garden
                                13-15 Sheep Street
                                Northampton
                                NN1 2LT
                            </address>
                        </div>

                        <div className="map" style={{ marginTop: "50px" }}>
                            <iframe title="Google maps" width="600" height="450"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.2355402764947!2d-0.9002941840085863!3d52.23910526476006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48770edd6e2a027d%3A0x69fa12b9e55f95f3!2sOriental%20Garden%20Restaurant!5e0!3m2!1sen!2suk!4v1566817898847!5m2!1sen!2suk">
                            </iframe>
                        </div>

                    </div>
                </section>
            </Layout >
        );
    }
}

